import { IconKanban } from "~/components/icons";

import Button from "~/components/button";
import ButtonGroup from "~/components/button-group";
import { IconList } from "~/components/icons";
import SearchFilter from "~/components/search-filter";

type Props = {
  mode: string;
  setMode: (mode: string) => void;
  setFilter?: (filter: string) => void;
};

export default function KanbanBoardPanelButtons({
  mode,
  setMode,
  setFilter
}: Props) {
  return (
    <>
      {setFilter && (
        <SearchFilter
          onSearch={setFilter}
          placeholder="Filter cards..."
          width={250}
          small
        />
      )}
      <ButtonGroup className="inline-flex">
        <Button
          small
          onClick={() => setMode("Board")}
          mode={mode === "Board" ? "primary" : "default"}
        >
          <IconKanban /> Board
        </Button>
        <Button
          small
          onClick={() => setMode("List")}
          mode={mode === "List" ? "primary" : "default"}
        >
          <IconList /> List
        </Button>
        <Button
          small
          onClick={() => setMode("Backlog")}
          mode={mode === "Backlog" ? "primary" : "default"}
        >
          <IconList /> Backlog
        </Button>
      </ButtonGroup>
    </>
  );
}
